/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import jQuery from 'jquery';
window.$ = jQuery;
require('./CookieEu');

import Trix from 'trix';
require("@rails/actiontext")

Trix.config.blockAttributes.default.tagName = "p"

function SelectCVTabs() {
  const CvTabs = document.querySelectorAll(".cv-tabhost__tab");
  if(CvTabs) {
    CvTabs.forEach((CvTab) => {
      CvTab.addEventListener("click", (event) => {
        const CvTabActive = document.querySelector(".cv-tabhost__tab--active");
        CvTabActive.classList.remove("cv-tabhost__tab--active");
        CvTab.classList.add("cv-tabhost__tab--active");
        const TabLength = CvTab.dataset.tab;
        ListCVTabs(TabLength);
      });
    })
  }
}

function ListCVTabs(tabLength) {
  const CvItems = document.querySelectorAll(".cv-table__item");
  if(CvItems) {
    CvItems.forEach((CvItem) => {
      const cvItemTab = CvItem.dataset.tablength;
      if(tabLength == "short") {
        if(tabLength == cvItemTab) {
          CvItem.style.display = "block";
        } else {
          CvItem.style.display = "none";
        }
      }
      else if(tabLength == "medium") {
        if(tabLength == cvItemTab || cvItemTab == "short") {
          CvItem.style.display = "block";
        } else {
          CvItem.style.display = "none";
        }
      }
      else if(tabLength == "long") {
        CvItem.style.display = "block";
      } else {
        CvItem.style.display = "none";
      }
    })
  }
}

function SelectContactType() {
  const type_buttons = document.querySelectorAll(".contact-tabhost__tab");

  if(type_buttons) {
    type_buttons.forEach((type_button) => {
      type_button.addEventListener("click", (event) => {

        type_buttons.forEach((type_button, index) => {
         type_button.classList.remove('contact-tabhost__tab--selected');

         var selected_icon = document.querySelector('.contact-tabhost__icon--'+ (index+1) +'-selected');
         if(selected_icon) {
          selected_icon.classList.remove('contact-tabhost__icon--'+ (index+1) +'-selected');
         }
        });

        const selected_contact_form = document.querySelector(".contact-form--selected");
        selected_contact_form.classList.remove('contact-form--selected');

        const select_contact_form = document.querySelector("#contact_" + type_button.dataset.contacttype)
        select_contact_form.classList.add('contact-form--selected');

        if(!type_button.classList.contains('contact-tabhost__tab--selected')) {
          type_button.classList.add('contact-tabhost__tab--selected');

          var icon = type_button.querySelector('.contact-tabhost__icon');
          icon.classList.add(icon.classList[1] + '-selected')

          document.getElementById('contact_contact_type').value = type_button.dataset.contacttype;
        }

      });
    });
  }
}

function resizeGridItem(item){
  const grid = document.getElementsByClassName("awards-grid")[0];
  const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  const rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
  item.style.gridRowEnd = "span "+rowSpan;
}

function resizeAllGridItems(){
  const allItems = document.getElementsByClassName("award-card");
  for(let x=0;x<allItems.length;x++){
    resizeGridItem(allItems[x]);
  }
}

function resizeInstance(instance){
  const item = instance.elements[0];
  resizeGridItem(item);
}

function isAnyPartOfElementInViewport(el) {

  const rect = el.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

  return (vertInView && horInView);
}

// window.onload = resizeAllGridItems();

// allItems = document.getElementsByClassName("item");
// for(x=0;x<allItems.length;x++){
//   imagesLoaded( allItems[x], resizeInstance);
// }

window.onload = function () {
  $('.main-header__burger-menu').on('click', function() {
    const $mainHeader = $('.main-header');
    if ($mainHeader.hasClass('main-header--menu-active')) {
      $mainHeader.removeClass('main-header--menu-active');
    } else {
      $mainHeader.addClass('main-header--menu-active');
    }
  });

  ListCVTabs("short");
  SelectCVTabs();
  SelectContactType();
  resizeAllGridItems();

  if(document.getElementById('canvas')) {
    var img = new Image();

    var canvas = document.getElementById('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    var context = canvas.getContext('2d');

    var x = 0;
    var frame = 0;
    var direction = "forward";

    var shape = {
      start: { x: 14, y: 27*1.5 },
      curves: [
        {
          cp1: { x: 19, y: 27*1.5 },
          cp2: { x: 19, y: 20*1.5 },
          end: { x: 25, y: 17*1.5 }
        },
        {
          cp1: { x: 30, y: 15*1.5 },
          cp2: { x: 38.5, y: 16*1.5 },
          end: { x: 40, y: 14*1.5 }
        },
        {
          cp1: { x: 42, y: 11*1.5 },
          cp2: { x: 34, y: 12.5*1.5 },
          end: { x: 33, y: 10*1.5 }
        },
        {
          cp1: { x: 32, y: 7.5*1.5 },
          cp2: { x: 37.5, y: 1*1.5 },
          end: { x: 31, y: 1*1.5 }
        },
        {
          cp1: { x: 15, y: 12*1.5 },
          cp2: { x: 2.5, y: 10*1.5 },
          end: { x: 1.5, y: 14*1.5 }
        },
        {
          cp1: { x: -2, y: 20*1.5 },
          cp2: { x: 4.5, y: 27*1.5 },
          end: { x: 14, y: 27*1.5 }
        }
      ]
    };

    function rotate(point, angle) {
      var r = angle * Math.PI / 360;

      return {
        x: point.x * Math.cos(r) - point.y * Math.sin(r),
        y: point.y * Math.cos(r) + point.x * Math.sin(r)
      }
    }

    function centerShape(shape) {
      var min = {
        x: shape.start.x,
        y: shape.start.y
      };
      var max = {
        x: shape.start.x,
        y: shape.start.y
      }

      shape.curves.forEach(function(curve) {
        if (curve.end.x < min.x) {
          min.x = curve.end.x;
        }
        if (curve.end.y < min.y) {
          min.y = curve.end.y;
        }
        if (curve.end.x > max.x) {
          max.x = curve.end.x;
        }
        if (curve.end.y > max.y) {
          max.y = curve.max.y;
        }
      });

      var width = max.x - min.x;
      var height = max.y - min.y;
      var width2 = width/2;
      var height2 = height/2;

      var newShape = {
        start: {
          x: shape.start.x - width2,
          y: shape.start.y - height2
        },
        curves: []
      }

      shape.curves.forEach(function(curve) {
        newShape.curves.push({
          cp1: { x: curve.cp1.x - width2, y: curve.cp1.y - height2 },
          cp2: { x: curve.cp2.x - width2, y: curve.cp2.y - height2 },
          end: { x: curve.end.x - width2, y: curve.end.y - height2 }
        });
      });

      return newShape;
    }

    function drawShape(context, shape, cx, cy, zoom, angle) {
      context.beginPath();
      var rStart = rotate(shape.start, angle);
      context.moveTo(rStart.x * zoom + cx, rStart.y * zoom + cy);
      for(var j=0;j<shape.curves.length;j++) {
        var rCp1 = rotate(shape.curves[j].cp1, angle);
        var rCp2 = rotate(shape.curves[j].cp2, angle);
        var rEnd = rotate(shape.curves[j].end, angle);

        context.bezierCurveTo(
          rCp1.x * zoom + cx, rCp1.y * zoom + cy,
          rCp2.x * zoom + cx, rCp2.y * zoom + cy,
          rEnd.x * zoom + cx, rEnd.y * zoom + cy
        );
      }
      context.stroke();
    }

    shape = centerShape(shape);

    function step(timestamp) {
      if(isAnyPartOfElementInViewport(canvas)) {
        context.globalAlpha = 1.0;
        context.fillStyle = "#1D0631";
        context.fillRect(0, 0, canvas.width, canvas.height);

        var gradient = context.createLinearGradient(20,0, 600,0);
        gradient.addColorStop(0, '#FAD961');
        gradient.addColorStop(.54, '#F76B1C');
        gradient.addColorStop(1, '#F71CA2');

        var alpha = 1.0;
        context.globalAlpha = alpha;
        var zoom = 1 + frame / 20;
        for(var i=0;i<100;i++) {
          zoom += 1;
          zoom %= 120;
          context.globalAlpha = alpha;
          alpha -= 1.0/100;
          context.strokeStyle = gradient;
          drawShape(context, shape, 200, 200, zoom, i*frame/600 + frame / 10);
        }

        frame += .75;
      }

      window.requestAnimationFrame(step);

      // if (frame > 2000) {
      //   direction = "reverse"
      // }
      // if (frame < -3000) {
      //   direction = "forward"
      // }

      // if (direction == "forward") {
      //   frame += 1;
      // } else {
      //   frame -= 1;
      // }

    }

    window.requestAnimationFrame(step);
  }

  if($(".awards-slider").length) {
    tns({
      container: '.awards-slider',
      mode: 'carousel',
      axis: 'horizontal',
      items: 4,
      controls: false,
      speed: 300,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayButtonOutput: false,
      slideBy: 4
    });
  }
}

function windowResized() {
  if(document.getElementById('canvas')) {
    var canvas = document.getElementById('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
  }
}

window.addEventListener("resize", windowResized);
